<template>
<!-- {{ value }} -->
  <div
    :class="labelclass && device == 'mobile' ? 'fnt-body' : '' || labelclassed"
    class="d-flex align-items-center"
    >{{ name != null ? $t(label, { fieldname: $t(name) }) : ($t(label)||value) }}
    <span>{{ require }}</span>
    <Button
      v-if="helper == true"
      class="btn p-0 m-0"
      :icon="icon"
      :classBtn="
        device == 'mobile' && classBtn == 'tooltiper'
          ? 'tooltiper-mobile'
          : classBtn
      "
      :tooltipDelay="tooltipDelay"
      :tooltipMsg="$t(tooltipMsg)"
      :tooltipPlacement="
        device == 'mobile' && (tooltipPlacement == 'tooltip-right' || tooltipPlacement == 'tooltip-left') ? 'tooltip-top-mobile'  : tooltipPlacement 
      "
      :widthIcon="widthIcon"
      :heightIcon="heightIcon"
      :classBtnIcon="classBtnIcon"
    />
  </div>
  <!-- <input type="hidden" name="" class="d-none" :value="value"> -->
</template>

<script>
import Button from "@/components/button/button.vue";
export default {
  props: {
    value: { type: String },
    name: { type: String },
    label: { type: String },
    labelclass: { type: String },
    labelclassed: { type: String },
    widthIcon: { type: Number, default:24 },
    heightIcon: { type: Number,default: 24 },
    require: { type: String, default: "" },
    helper: { type: Boolean, default: false },
    icon: { type: String, default: "" },
    classBtn: { type: String, default: "" },
    classBtnIcon: { type: String, default: "" },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipMsg: { type: String, default: "" },
    tooltipPlacement: { type: String, default: "" },
  },
  components: {
    Button,
  },
};
</script>

<style scoped lang="scss">
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 150%;
    transform: translateX(-50%);
  }
}
.tooltip-top-mobile {
  &::before {
    left: -150%;
    bottom: 116%;
    transform: translateX(-50%);
  }
  &::after {
    left: 47%;
    bottom:102%;
    transform: translateX(-50%) rotate(45deg);
  }
}
.tooltip-right {
  &::before {
    left: calc(100% + 12.5px);
    top: 50%;
    transform: translateY(-50%);
  }
}
label {
  width: max-content;
}
.tooltip-left {
  &::before {
    right: calc(100% + 12.5px);
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
